'use client'

import { Promo as PromoUI } from '@chaseweb/ui-library/src'
import { useEffect, useRef } from 'react'

import { DownloadLink, Link } from '@/components'
import type { FeatureTypeIdEnum } from '@/constants'
import { useCTAStore } from '@/lib/stores'

import type { ComponentProps } from '../../types'

export const PromoCta = ({
  featureData,
}: ComponentProps<FeatureTypeIdEnum.PROMO_FEATURE>) => {
  const promoCtaRef = useRef<HTMLDivElement>(null)
  const { setPromoCtaRef } = useCTAStore()

  useEffect(() => {
    if (promoCtaRef.current) {
      setPromoCtaRef(promoCtaRef)
    }
    return () => setPromoCtaRef(null)
  }, [setPromoCtaRef])

  return (
    <div ref={promoCtaRef} className="tw-min-h-[75px]">
      <PromoUI.Cta asChild noExternalIcon>
        {featureData.cta.link ? (
          <Link
            sectionLabel="promo"
            trackingActionLabel={featureData.cta.label}
            href={featureData.cta.link}
          >
            {featureData.cta.label}
          </Link>
        ) : (
          <DownloadLink sectionLabel="promo">
            {featureData.cta.label}
          </DownloadLink>
        )}
      </PromoUI.Cta>
    </div>
  )
}
